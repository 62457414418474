<template>
    <div v-if="score.win" class="puzzle_scores">
        <div class="puzzle_score wins"><span>{{ score.win }}</span> Wins</div>
        <div class="puzzle_score puzzle-moves">Moves record <span>{{ score.moves }}</span></div>
        <div class="puzzle_score">Best time<formatted-timer :entry="score.record"></formatted-timer></div>
    </div>
    <div v-else class="scores empty">No recorded scores yet, keep playing!</div>
</template>

<script>
import FormattedTimer   from './FormattedTimer'

export default {
    name: "ScorePanel",
    components: { 'formatted-timer': FormattedTimer },
    props: { score : { type: Object } },
    mounted() {
        if (!localStorage.puzzlescore) return
        this.$emit('update:score', JSON.parse(localStorage.puzzlescore))  // Writing score to local storage
    },
    watch: {
        score: {
            handler(newScore) {
                localStorage.puzzlescore = JSON.stringify(newScore)
            },
            deep: true
        }
    }
}
</script>